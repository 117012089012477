import React, { useState } from 'react'
import { MoreOutlined } from '@ant-design/icons'
import { Tooltip, Dropdown, Menu, message, Modal, Input, Empty, Alert } from 'antd'

import RefreshButton from 'components/RefreshButton'

import StyledApprovalChainBox from 'components/common/styled-components/StyledApprovalChainBox'
import FitmentApprovalsService from 'services/users/fitment-approvals.service'
import globalStore from 'store/index'
import FitmentsService from 'services/users/fitments.service'
import BudgetApprovalsService from 'services/users/budget-approvals.service'
import SalaryApprovalsService from 'services/users/salary-approvals.service'

const ApprovalChainStatusesBox = ({
	useApi,
	approvalsId = null,
	approvalChainStatuses,
	showStatus = true,
	title = 'Approval Chain',
	showRefresh = false,
	refresh = () => {},
	onSetData,
	ifEmptyMessage,
	children,
}) => {
	const [modalVisible, setModalVisible] = useState(false)
	const [comment, setComment] = useState(null)
	const [rejectButtonLoading, setRejectButtonLoading] = useState(false)
	const [approvalId, setApprovalId] = useState(null)

	const NoApprovalChainFound = () => (
		<StyledApprovalChainBox>
			<div className='card'>
				<h1>
					{title}
					{showRefresh && (
						<>
							<RefreshButton refresh={refresh} />
							{children}
						</>
					)}
				</h1>
				{!ifEmptyMessage ? (
					<Empty
						description='No approval chain found'
						style={{ display: 'flex', flexDirection: 'column' }}
					/>
				) : (
					<Alert message={ifEmptyMessage} type='info' showIcon />
				)}
			</div>
		</StyledApprovalChainBox>
	)

	if (!approvalChainStatuses) return <NoApprovalChainFound />
	if (approvalChainStatuses.length === 0) return <NoApprovalChainFound />

	const onMenuItemClick = async ({ key }) => {
		const [action, id] = key.split('-')
		if (!id) return
		if (!action) return

		const pageState = {}

		if (action === 'approve') {
			if (useApi === 'tentative') {
				message.loading({
					content: 'Approving Budget approval...',
					key: 'updatable',
				})

				await BudgetApprovalsService.approve({
					pageState,
					budgetTentativeValueId: id,
				})

				const { budget_approval, budgetApprovalValuesErrors } = pageState

				if (budgetApprovalValuesErrors) {
					message.error({
						content: budgetApprovalValuesErrors[0],
						key: 'updatable',
					})
					return
				}

				onSetData(budget_approval?.budget_tentative_value)

				message.success({
					content: 'Budget approval approved.',
					key: 'updatable',
				})

				return
			}
			if (useApi === 'salary') {
				message.loading({
					content: 'Approving Budget approval...',
					key: 'updatable',
				})

				await SalaryApprovalsService.approve({
					pageState,
					salaryApprovalId: id,
				})

				const { salary_approval, salaryApprovalValuesErrors } = pageState

				if (salaryApprovalValuesErrors) {
					message.error({
						content: salaryApprovalValuesErrors[0],
						key: 'updatable',
					})
					return
				}
				onSetData(salary_approval.fitment)

				message.success({
					content: 'Salary approval approved.',
					key: 'updatable',
				})

				return
			}
			message.loading({
				content: 'Approving fitment approval...',
				key: 'updatable',
			})

			await FitmentApprovalsService.approve({
				pageState,
				fitmentApprovalId: id,
			})

			const { fitment_approval, fitmentApprovalErrors } = pageState

			if (fitmentApprovalErrors) {
				message.error({
					content: fitmentApprovalErrors[0],
					key: 'updatable',
				})
				return
			}

			globalStore.ui.fitments.show.fitment = fitment_approval.fitment
			onSetData(fitment_approval.fitment)

			message.success({
				content: 'Fitment approval approved.',
				key: 'updatable',
			})
		}

		if (action === 'reject') {
			setApprovalId(id)
			setModalVisible(true)
		}
		if (action === 'resend') {
			if (!approvalsId) return

			message.loading({
				content: 'Sending mail to approver...',
				key: 'updatable',
			})
			if (useApi === 'salary') {
				await FitmentsService.resendMailForSalaryApprover({
					pageState,
					fitmentId: approvalsId,
					values: {
						salary_approval_id: id,
					},
				})

				const { fitment } = pageState

				if (!fitment) return

				globalStore.ui.fitments.show.fitment = fitment
				onSetData(fitment)

				message.success({
					content: 'Sent mail to approver.',
					key: 'updatable',
				})
			}

			await FitmentsService.resendApprovalMail({
				pageState,
				fitmentId: approvalsId,
				values: {
					fitment_approval_id: id,
				},
			})

			const { fitment } = pageState

			if (!fitment) return

			globalStore.ui.fitments.show.fitment = fitment
			onSetData(fitment)

			message.success({
				content: 'Sent mail to approver.',
				key: 'updatable',
			})
		}
	}

	const rejectFitmentApproval = async () => {
		if (!comment) {
			message.error("Reason couldn't be blank")
			return
		}

		const pageState = {}

		const postData = {
			comment,
		}

		if (useApi === 'tentative') {
			message.loading({
				content: 'Rejecting budget approval...',
				key: 'updatable',
			})

			setRejectButtonLoading(true)
			await BudgetApprovalsService.reject({
				pageState,
				budgetTentativeValueId: approvalId,
				values: postData,
			})

			const { budget_approval, budgetApprovalValuesErrors } = pageState

			if (budgetApprovalValuesErrors) {
				message.error({
					content: budgetApprovalValuesErrors[0],
					key: 'updatable',
				})
				return
			}

			setRejectButtonLoading(false)
			setModalVisible(false)
			onSetData(budget_approval?.budget_tentative_value)
			message.success({ content: 'Budget rejected.', key: 'updatable' })

			return
		}
		if (useApi === 'salary') {
			message.loading({
				content: 'Rejecting salary approval...',
				key: 'updatable',
			})

			setRejectButtonLoading(true)
			await SalaryApprovalsService.reject({
				pageState,
				salaryApprovalId: approvalId,
				values: postData,
			})

			const { salary_approval, salaryApprovalValuesErrors } = pageState

			if (salaryApprovalValuesErrors) {
				message.error({
					content: salaryApprovalValuesErrors[0],
					key: 'updatable',
				})
				return
			}

			setRejectButtonLoading(false)
			setModalVisible(false)
			onSetData(salary_approval?.fitment)
			message.success({ content: 'Salary rejected.', key: 'updatable' })
			return
		}

		message.loading({
			content: 'Rejecting fitment approval...',
			key: 'updatable',
		})

		setRejectButtonLoading(true)
		await FitmentApprovalsService.reject({
			pageState,
			fitmentApprovalId: approvalId,
			values: postData,
		})
		setRejectButtonLoading(false)

		const { fitmentApprovalErrors, fitment_approval } = pageState

		if (fitmentApprovalErrors) {
			message.error({ content: fitmentApprovalErrors[0], key: 'updatable' })
			return
		}

		onSetData(fitment_approval.fitment)
		setModalVisible(false)
		setApprovalId(null)

		message.success({ content: 'Fitment rejected.', key: 'updatable' })

		globalStore.ui.fitments.show.fitment = fitment_approval.fitment
	}

	const actionButtons = (id) => (
		<Menu onClick={onMenuItemClick}>
			<Menu.Item key={`approve-${id}`}>Approve</Menu.Item>
			<Menu.Item key={`reject-${id}`} danger>
				Reject
			</Menu.Item>
			<Menu.Item
				key={`resend-${id}`}
				style={{ display: useApi === 'tentative' ? 'none' : '' }}
				primary>
				Resend Approval Mail
			</Menu.Item>
		</Menu>
	)

	const userRoles = globalStore.currentUser?.roles || []

	return (
		<StyledApprovalChainBox>
			<div className='card'>
				<h1>{title}</h1>
				<div style={{ display: 'flex', gap: 50 }}>
					{approvalChainStatuses.map((approvers, i) => {
						return (
							<div key={i} className='approvers-box'>
								{approvers.map((approver, index) => {
									let statusClass = 'not-initiated'

									if (approver.status === 'approved') {
										statusClass = 'approved'
									}
									if (approver.status === 'rejected') {
										statusClass = 'rejected'
									}
									if (approver.status === 'pending') {
										statusClass = 'pending'
									}

									return (
										<div className={`chip ${statusClass}`} key={index}>
											<span className='email'>{approver.email}</span>
											{showStatus && approver.status && (
												<Tooltip title={approver.status}>
													<div className='status'></div>
												</Tooltip>
											)}
											{(userRoles.includes('super-admin') || userRoles.includes('admin')) &&
												showStatus &&
												approver.status === 'pending' && (
													<Dropdown overlay={actionButtons(approver.id)}>
														<a
															href='# '
															className='ant-dropdown-link'
															onClick={(e) => e.preventDefault()}>
															<MoreOutlined />
														</a>
													</Dropdown>
												)}
										</div>
									)
								})}
							</div>
						)
					})}
				</div>
			</div>
			<Modal
				title='Reason'
				closable={false}
				maskClosable={false}
				visible={modalVisible}
				onOk={rejectFitmentApproval}
				okText='Reject'
				confirmLoading={rejectButtonLoading}
				onCancel={() => setModalVisible(false)}>
				<Input.TextArea
					allowClear
					autoSize={{ minRows: 4 }}
					onChange={(e) => setComment(e.target.value)}
				/>
			</Modal>
		</StyledApprovalChainBox>
	)
}

export default ApprovalChainStatusesBox
